import React, { Suspense } from 'react';
import Header from './header';
import Aside from './aside';
import { Routes } from '../routes'; // Ensure this is correctly imported
import { Route, Routes as RouterRoutes } from 'react-router-dom';
import PrivateRoute from '../midleware/privateRoute';

const Base = () => {
    return (
        <React.Fragment>
            <Aside />
            <div className="blur-background">
                <div className="gradient-container">
                    <div className="bg-gradient-spin"></div>
                </div>
            </div>
            <main className="content position-relative">
                <Header />
                <Suspense fallback={<div>Loading</div>}>
                    <RouterRoutes>
                        {Routes.map((route, index) => (
                            <Route
                                key={index}
                                path={route.path}
                                element={
                                    <PrivateRoute>
                                        <route.component />
                                    </PrivateRoute>
                                } // Ensure JSX is used here
                                exact={route.exact}
                            />
                        ))}
                    </RouterRoutes>
                </Suspense>
            </main>
        </React.Fragment>
    );
};

export default Base;
