import React from 'react';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

function PrivateRoute({ children }) {
    const isAuthenticated = () => {
        const token = localStorage.getItem('token');
        try {
            jwtDecode(token);  // Decode the token to check if it's valid
            return true;
        } catch (error) {
            return false;
        }
    }

    if (!isAuthenticated()) {
        // return <Navigate to="/login" />;
        window.location.href = "/login";
    }

    return children;  // Render the protected route if authenticated
}

export default PrivateRoute;
