import React from "react";

export const Menu = [
    {
        name: "Dashboard",
        path: "/",
        icon: "tachometer-alt",
        childrens: [] // No children, so leave it empty
    },
    // {
    //     name: "Roles",
    //     icon: "user-shield",
    //     childrens: [
    //         {
    //             name: "Role Names",
    //             path: "/roles/role_names",
    //             icon: "id-card",
    //         },
    //         {
    //             name: "Pages",
    //             path: "/roles/page_names",
    //             icon: "file-alt",
    //         },
    //         {
    //             name: "Role Permissions",
    //             path: "/roles/permissions",
    //             icon: "lock",
    //         }
    //     ]
    // },
    {
        name: "Categories & Learning path",
        icon: "user-shield",
        childrens: [
            {
                name: "Categories",
                path: "/categories/lists",
                icon: "id-card",
            },
            {
                name: "Learning Path",
                path: "/categories/learning_path",
                icon: "file-alt",
            },
            {
                name: "Course Source",
                path: "/categories/course_source",
                icon: "file-alt",
            }
        ]
    },
    {
        name: "Users",
        icon: "users",
        childrens: [
            {
                name: "Students",
                path: "/students/list",
                icon: "id-card",
            },
            {
                name: "Teachers",
                path: "/teachers/list",
                icon: "file-alt",
            },
            {
                name: "Add User",
                path: "/user/new",
                icon: "file-alt",
            }
        ]
    },
    {
        name: "Courses",
        icon: "users",
        childrens: [
            {
                name: "Courses",
                path: "/courses/lists",
                icon: "id-card",
            },
            {
                name: "Course Meetings",
                path: "/courses/live_classes",
                icon: "file-alt",
            }
        ]
    },
    {
        name: "Live Session",
        path: "/live_session/list", // Placeholder, update as needed
        icon: "video",
        childrens: []
    },
    {
        name: "Stock Screener",
        icon: "users",
        childrens: [
            {
                name: "Stock Screener Types",
                path: "/stock_screener_types/list",
                icon: "id-card",
            },
            {
                name: "Stock Screener",
                path: "/stock_screener/list",
                icon: "file-alt",
            }
        ]
    },
    {
        name: "Chats",
        path: "/chats", // Placeholder, update as needed
        icon: "user",
        childrens: []
    },
    {
        name: "Podcasts",
        path: "/podcast/lists", // Placeholder, update as needed
        icon: "podcast",
        childrens: []
    },
    {
        name: "Foundation",
        icon: "users",
        childrens: [
            {
                name: "Foundation Categories",
                path: "/foundation/categories",
                icon: "id-card",
            },
            {
                name: "Foundations & Videos",
                path: "/foundation/videos",
                icon: "file-alt",
            }
        ]
    },
];
