import React, { lazy } from "react";

// Lazy load components

const Dashboard = lazy(() => import("./Views/Admin/Dashboard/dashboard"));
const Roles = lazy(() => import("./Views/Admin/Roles/Lists"));
const Pages = lazy(() => import("./Views/Admin/Roles/Pages/Lists"));
const RolePermissions = lazy(() => import("./Views/Admin/Roles/RolePermissions/Lists"));

// Categories & Learning Paths
const Categories = lazy(() => import("./Views/Admin/Categories/Lists"));
const LearningPaths = lazy(() => import("./Views/Admin/Categories/LearningPaths/Lists"));
const CourseSources = lazy(() => import("./Views/Admin/Categories/CourseSource/Lists"));

// Course
const AddNewCourse = lazy(() => import("./Views/Admin/Courses/AddNewCourse"));
const CourseLists = lazy(() => import("./Views/Admin/Courses/Lists"));
const EditCourse = lazy(() => import("./Views/Admin/Courses/EditCourse"));

// Course Live Classes
const CourseLiveClassesLists = lazy(() => import("./Views/Admin/CourseLiveClasses/Lists"));

// User
const AddUser = lazy(() => import("./Views/Admin/Users/User/AddUser"));
const EditUser = lazy(() => import("./Views/Admin/Users/User/EditUser"));
const UserProfile = lazy(() => import("./Views/Admin/Users/User/Profile"));

// Students
const StudentLists = lazy(() => import("./Views/Admin/Users/Students/Lists"));

// Techers
const TeacherLists = lazy(() => import("./Views/Admin/Users/Teachers/Lists"));

// Stock Screener
const StockScreenerTypeLists = lazy(() => import("./Views/Admin/StockScreener/Types/Lists"));
const StockScreenerLists = lazy(() => import("./Views/Admin/StockScreener/StockScreener/Lists"));
const StockScreenerAdd = lazy(() => import("./Views/Admin/StockScreener/StockScreener/AddNew"));
const StockScreenerEdit = lazy(() => import("./Views/Admin/StockScreener/StockScreener/EditStockScreener"));

// Chats
const ChatsLists = lazy(() => import("./Views/Admin/Chats/Lists"));

// Podcast
const PodcastLists = lazy(() => import("./Views/Admin/Podcast/Lists"));
const PodcastCreate = lazy(() => import("./Views/Admin/Podcast/Create"));
const PodcastEdit = lazy(() => import("./Views/Admin/Podcast/Edit"));

// Live Session
const LiveSessionLists = lazy(() => import("./Views/Admin/LiveSession/Lists"));
const LiveSessionCreate = lazy(() => import("./Views/Admin/LiveSession/Create"));

// Foundation Categories
const FoundationCategories = lazy(() => import("./Views/Admin/Foundation/Categories/Lists"));
const FoundationVideos = lazy(() => import("./Views/Admin/Foundation/Videos/Lists"));
const FoundationVideosCreate = lazy(() => import("./Views/Admin/Foundation/Videos/AddNew"));

// Live Meeting
const LiveMeeting = lazy(() => import("./Views/Components/LiveMeeting/LiveMeeting"));

// Define the routes
export const Routes = [
    {
        path: "/",
        component: Dashboard,
        exact: true
    },
    {
        path: "/dashboard",
        component: Dashboard,
        exact: true
    },
    {
        path: "/roles/role_names",
        component: Roles,
        exact: true
    },
    {
        path: "/roles/page_names",
        component: Pages,
        exact: true
    },
    {
        path: "/roles/permissions",
        component: RolePermissions,
        exact: true
    },
    {
        path: "/categories/lists",
        component: Categories,
        exact: true
    },
    {
        path: "/categories/learning_path",
        component: LearningPaths,
        exact: true
    },
    {
        path: "/categories/course_source",
        component: CourseSources,
        exact: true
    },
    {
        path: "/courses/lists",
        component: CourseLists,
        exact: true
    },
    {
        path: "/courses/new",
        component: AddNewCourse,
        exact: true
    },
    {
        path: "/courses/edit/:courseIdParam",
        component: EditCourse,
        exact: true
    },
    {
        path: "/students/list",
        component: StudentLists,
        exact: true
    },
    {
        path: "/user/new",
        component: AddUser,
        exact: true
    },
    {
        path: "/user/edit/:userId",
        component: EditUser,
        exact: true
    },
    {
        path: "/user/profile",
        component: UserProfile,
        exact: true
    },
    {
        path: "/teachers/list",
        component: TeacherLists,
        exact: true
    },
    {
        path: "/stock_screener_types/list",
        component: StockScreenerTypeLists,
        exact: true
    },
    {
        path: "/stock_screener/list",
        component: StockScreenerLists,
        exact: true
    },
    {
        path: "/stock_screener/new",
        component: StockScreenerAdd,
        exact: true
    },
    {
        path: "/stock_screener/edit/:Id",
        component: StockScreenerEdit,
        exact: true
    },
    {
        path: "/courses/live_classes",
        component: CourseLiveClassesLists,
        exact: true
    },
    {
        path: "/chats",
        component: ChatsLists,
        exact: true
    },
    {
        path: "/podcast/lists",
        component: PodcastLists,
        exact: true
    },
    {
        path: "/podcast/create",
        component: PodcastCreate,
        exact: true
    },
    {
        path: "/podcast/edit/:id",
        component: PodcastEdit,
        exact: true
    },
    {
        path: "/live_session/list",
        component: LiveSessionLists,
        exact: true
    },
    {
        path: "/live_session/create",
        component: LiveSessionCreate,
        exact: true
    },
    {
        path: "/live_session/edit/:id",
        component: LiveSessionCreate,
        exact: true
    },
    {
        path: "/foundation/categories",
        component: FoundationCategories,
        exact: true
    },
    {
        path: "/foundation/videos",
        component: FoundationVideos,
        exact: true
    },
    {
        path: "/foundation/videos/create",
        component: FoundationVideosCreate,
        exact: true
    },
    {
        path: "/foundation/videos/edit/:id",
        component: FoundationVideosCreate,
        exact: true
    },
    {
        path: "/meetings/:meetingId/course_meeting",
        component: LiveMeeting,
        exact: true
    },
    {
        path: "/meetings/:liveSessionId/live_session",
        component: LiveMeeting,
        exact: true
    },
];
