import React, { Suspense } from 'react';
import './App.css';
import Base from './Layouts/base';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Auth
const Login = React.lazy(() => import('../src/Views/Admin/Auth/Login'));
function App() {
    return (
        <React.Fragment>
            <Router basename="/">
                <Suspense fallback={<div>Loading....</div>}>
                    <Routes>
                        <Route exact path="/login" name="Login Page" element={<Login />} />
                        {/* Render the Base component which will handle the layout and nested routes */}
                        <Route path="*" element={<Base />} />
                    </Routes>
                </Suspense>
            </Router>
        </React.Fragment>
    );
}

export default App;
