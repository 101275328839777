import React, { useState, useEffect } from 'react';
const Header = () => {
    const logout = (e) => {
        e.preventDefault();
        localStorage.clear();
        window.location.href = "/login";
    };

    // State to manage theme, default is light
    const [theme, setTheme] = useState('light');

    // UseEffect to apply theme on component mount
    useEffect(() => {
        document.documentElement.setAttribute('data-theme', theme);
    }, [theme]);

    // State to handle dropdown visibility
    const [isDropdownOpen, setDropdownOpen] = useState(false);

    // Function to toggle dropdown
    const toggleDropdown = () => {
        setDropdownOpen((prev) => !prev);
    };

    // Function to toggle the theme
    const toggleTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        setTheme(newTheme);
    };

    return (
        <div className="navbar-header" bis_skin_checked="1">
            <div className="row align-items-center justify-content-between float-end" bis_skin_checked="1">
                <div className="col-auto text-right">
                    <div className="d-flex flex-wrap align-items-center gap-3">
                        <a
                            id="theme-toggle"
                            className="w-40-px h-40-px bg-neutral-200 rounded-circle d-flex justify-content-center align-items-center border-1px-white text-decoration-none"
                            onClick={toggleTheme}
                            aria-label={theme === 'light' ? 'dark' : 'light'} // ARIA label will change based on the current theme
                            href="#"
                        >
                            <i
                                id="theme-icon"
                                className={`fas ${theme === 'light' ? 'fa-moon' : 'fa-sun'} clr-white`} // Icon will change based on theme
                            ></i>
                        </a>

                        {/*<div className="dropdown">*/}
                        {/*    <a className="has-indicator w-40-px h-40-px bg-neutral-200 rounded-circle d-flex justify-content-center align-items-center border-1px-white text-decoration-none"*/}
                        {/*       type="button" data-bs-toggle="dropdown">*/}
                        {/*        <i className="fas fa-envelope text-primary-light clr-white"></i>*/}
                        {/*    </a>*/}
                        {/*</div>*/}

                        {/*<div className="dropdown">*/}
                        {/*    <a className="has-indicator w-40-px h-40-px bg-neutral-200 rounded-circle d-flex justify-content-center align-items-center border-1px-white text-decoration-none"*/}
                        {/*       type="button" data-bs-toggle="dropdown">*/}
                        {/*        <i className="fas fa-bell text-primary-light clr-white"></i>*/}
                        {/*    </a>*/}
                        {/*</div>*/}

                        <div className="dropdown position-relative">
                            <a
                                className="d-flex justify-content-center align-items-center rounded-circle"
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    toggleDropdown();
                                }}
                                aria-expanded={isDropdownOpen}
                            >
                                <img
                                    src="https://th.bing.com/th/id/OIP.tvaMwK3QuFxhTYg4PSNNVAHaHa?rs=1&pid=ImgDetMain"
                                    alt="Profile"
                                    className="w-40-px h-40-px object-fit-cover rounded-circle"
                                />
                            </a>
                            {isDropdownOpen && (
                                <div className="dropdown-menu position-absolute end-0 mt-3 p-3 bg-white shadow-lg border rounded-3 show" style={{ minWidth: '280px' }}>
                                    {/* Header Section */}
                    {/*                <div className="p-3 bg-primary bg-opacity-10 rounded-3 mb-3">*/}
                    {/*                    <div className="d-flex align-items-center justify-content-between">*/}
                    {/*                        <div>*/}
                    {/*                            <h6 className="fs-6 fw-bold text-primary mb-1">*/}
                    {/*                                Shaidul Islam*/}
                    {/*                            </h6>*/}
                    {/*                            <span className="text-secondary small fw-medium">*/}
                    {/*    Admin*/}
                    {/*</span>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}

                                    {/* Menu Items */}
                                    <ul className="list-unstyled m-0">
                                        {/*<li>*/}
                                        {/*    <a*/}
                                        {/*        href="view-profile.html"*/}
                                        {/*        className="dropdown-item d-flex align-items-center gap-3 p-3 text-dark rounded-2 text-decoration-none"*/}
                                        {/*    >*/}
                                        {/*        <i className="fas fa-user text-primary"></i>*/}
                                        {/*        <span>My Profile</span>*/}
                                        {/*    </a>*/}
                                        {/*</li>*/}
                                        {/*<li>*/}
                                        {/*    <a*/}
                                        {/*        href="email.html"*/}
                                        {/*        className="dropdown-item d-flex align-items-center gap-3 p-3 text-dark rounded-2 text-decoration-none"*/}
                                        {/*    >*/}
                                        {/*        <i className="fas fa-envelope-open-text text-primary"></i>*/}
                                        {/*        <span>Inbox</span>*/}
                                        {/*    </a>*/}
                                        {/*</li>*/}
                                        {/*<li>*/}
                                        {/*    <a*/}
                                        {/*        href="company.html"*/}
                                        {/*        className="dropdown-item d-flex align-items-center gap-3 p-3 text-dark rounded-2 text-decoration-none"*/}
                                        {/*    >*/}
                                        {/*        <i className="fas fa-cog text-primary"></i>*/}
                                        {/*        <span>Settings</span>*/}
                                        {/*    </a>*/}
                                        {/*</li>*/}

                                        {/*<li className="border-top my-2"></li>*/}

                                        <li>
                                            <a
                                                href="#"
                                                onClick={logout}
                                                className="dropdown-item d-flex align-items-center gap-3 p-3 text-danger rounded-2 text-decoration-none"
                                            >
                                                <i className="fas fa-sign-out-alt"></i>
                                                <span>Log Out</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Header;