import axios from 'axios';

// const userEmail = localStorage.getItem('email');
export default axios.create({
    // baseURL: "http://localhost:8000/api/v1",
    baseURL: "https://api.tradebeez.com/api/v1",
    headers: {
        "Access-Control-Allow-Headers" : "Content-Type",
        "Access-Control-Allow-Origin": "https://api.tradebeez.com",
        "Access-Control-Allow-Methods": "OPTIONS,POST,GET,PUT",
        "Content-type": "application/json",
        "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxIiwiZXhwIjoxNzYxMjM4Njg1fQ.98_omI1BeVVfX85E-MEDbJb5xROwy29RZMXswg6D9pI"
    }
});