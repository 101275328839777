import http from "../http_common";

class RolesPermissionService {
    getAll() {
        return http.get("/permissions");
    }

    create(data) {
        return http.post("/permissions", data);
    }

    getDataByID(id) {
        return http.get(`/permissions/${id}`);
    }

    updateData(id, data) {
        return http.put(`/permissions/role/${id}`, data);
    }

    delete(id) {
        return http.delete(`/permissions/${id}`);
    }

    deleteRolePermissionsByRoleID(id) {
        return http.delete(`/permissions/delete_by_role/${id}`);
    }

    getRolePermissionsByRoleID(id) {
        return http.get(`/permissions/check_role_permissions/${id}`);
    }

}

export default new RolesPermissionService();