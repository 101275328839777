import React, { useState, useEffect } from "react";
import { Menu } from '../menu';
import { Link } from "react-router-dom";
import rolePermisionServices from '../Services/roles_permission_services'; // Adjust the path

const Aside = () => {
    const [dropdownOpen, setDropdownOpen] = useState({}); // To manage dropdowns for each menu item
    const [userRole, setUserRole] = useState(null); // To store the user role data
    const [filteredMenu, setFilteredMenu] = useState(Menu); // Store filtered menu

    // Function to toggle the dropdown
    const toggleDropdown = (index) => {
        setDropdownOpen((prev) => ({
            ...prev,
            [index]: !prev[index], // Toggle the dropdown for the selected index
        }));
    };

    // Fetch roleId and rolePermissions when the component mounts
    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        const roleId = userInfo?.roleId;

        if (roleId) {
            // Call the API to get the role permissions by roleId
            rolePermisionServices.getRolePermissionsByRoleID(roleId).then((permissions) => {
                setUserRole(userInfo);

                // Filter the menu based on the role permissions (only show items with at least one permission: read or write)
                const filtered = Menu.map(item => {
                    if (item.childrens.length > 0) {
                        const filteredChildren = item.childrens.filter(child => {
                            // Find the page in the permissions API response that matches the child's name
                            const pagePermission = permissions.data.pages.find(page => page.page.name === child.name);

                            // Check if the page exists in the permissions and if either read or write is true
                            return pagePermission && (pagePermission.permission.read || pagePermission.permission.write);
                        });

                        // Only return item if there are any children with valid permissions
                        return { ...item, childrens: filteredChildren };
                    }

                    // Check if the page exists in the permissions and if either read or write is true for the item
                    const pagePermission = permissions.data.pages.find(page => page.page.name === item.name);
                    if (pagePermission && (pagePermission.permission.read || pagePermission.permission.write)) {
                        return item;  // Return the item if it has valid permissions
                    }
                    return null;  // Do not return this item if no valid permissions
                }).filter(item => item !== null);  // Remove any null items (those that had no valid permissions)

                // Add "Dashboard" menu first
                const topMenus = [
                    {
                        name: "Dashboard",
                        path: "/",
                        icon: "tachometer-alt",
                        childrens: [] // No children
                    }
                ];

                // Add "Roles" menu only if the roleName is "Super Admin"
                if (userInfo?.roleName === "Super Admin") {
                    const rolesMenu = {
                        name: "Roles",
                        icon: "user-shield",
                        childrens: [
                            {
                                name: "Role Names",
                                path: "/roles/role_names",
                                icon: "id-card",
                            },
                            {
                                name: "Pages",
                                path: "/roles/page_names",
                                icon: "file-alt",
                            },
                            {
                                name: "Role Permissions",
                                path: "/roles/permissions",
                                icon: "lock",
                            }
                        ]
                    };
                    topMenus.push(rolesMenu); // Add the "Roles" menu if the condition matches
                }

                // Combine the top menus and filtered menu
                setFilteredMenu([...topMenus, ...filtered]); // Ensure Dashboard and Roles appear first
            }).catch((error) => {
                console.error("Error fetching role permissions:", error);
            });
        }
    }, []);

    return (
        <aside className="sidebar">
            <a className="navbar-brand d-flex" href="#">
                <img className="mar0-auto" src="../../assets/images/logo.png" alt="Logo" />
            </a>
            <nav className="nav flex-column mt-4">
                <div className="sidebar-menu-area">
                    <ul className="nav-list">
                        {filteredMenu.map((item, index) => (
                            <li className="nav-item" key={index}>
                                {item.childrens.length > 0 ? (
                                    <>
                                        <div className="nav-link" onClick={() => toggleDropdown(index)} style={{ cursor: 'pointer' }}>
                                            <i className={`fas fa-${item.icon}`}></i> {item.name}
                                            <i className={`fas ${dropdownOpen[index] ? 'fa-chevron-up' : 'fa-chevron-down'}`} style={{ float: 'right' }}></i>
                                        </div>
                                        {dropdownOpen[index] && (
                                            <ul className="nav flex-column pl-4">
                                                {item.childrens.map((child, childIndex) => (
                                                    <li className="nav-item pl-10per" key={childIndex}>
                                                        <Link className="nav-link" to={child.path}>
                                                            <i className={`fas fa-${child.icon}`}></i> {child.name}
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </>
                                ) : (
                                    <Link className="nav-link" to={item.path}>
                                        <i className={`fas fa-${item.icon}`}></i> {item.name}
                                    </Link>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            </nav>
        </aside>
    );
}

export default Aside;
